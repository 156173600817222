import React, { useEffect, useMemo } from 'react';
import {useHistory, useRouteMatch} from "react-router-dom";
import {IRouteParams} from "../../../../AppRoutes";
import PageContainer from "../../../../components/ui/PageContainer";
import {Link, Box, Divider, Paper, Typography, useTheme} from "@material-ui/core";
import useSharedStyles from "../../../../components/useSharedStyles";
import {useLayoutEffect, useState} from "react";
import {justFetch} from "../../../../mutations/mutate";
import endpoints from "../../../../endpoints";
import SiteToSchoolBox from "./components/SiteToSchoolBox";
import Button from "../../../../components/ui/buttons/Button";
import {useAlert} from "../../../../context/AlertProvider";
import {routes} from "../../../../routes";
import SiteNameBox from "./components/SiteNameBox";
import { IAnonymousQuoteResponse } from 'pages/sales/IQuote';

const purp = '#C32F96'

type ConfirmationKVProps = {
  label: string;
  value: string | undefined | JSX.Element | Date;
};

const ConfirmationKV: React.FC<ConfirmationKVProps> = ({ label, value }) => {
  const renderValue = (value: string | undefined | JSX.Element | Date) => {
    if (typeof value === 'string') {
      return value.split('\n').map((part, idx, { length }) => (
        <React.Fragment key={idx}>
          {part}
          {idx < length - 1 && <br />}
        </React.Fragment>
      ));
    }
    if (React.isValidElement(value) && value.type === 'JSX.Element') {
      return value;
    }
    return value;
  };

  return (
    <Box display="flex" alignItems="flex-start">
      <Box minWidth="250px">
        <Typography variant="subtitle1">{label}</Typography>
      </Box>
      <Typography>{renderValue(value)}</Typography>
    </Box>
  );
};

type IOrderRequestV2 = {
  id: number;
  order: IOrderDetails;
  processed: boolean;
  created_at: string;
  updated_at: string;
  organization_profile_id: number | null;
};

type IOrderDetails = {
  num_sites: number;
  site_names: string[];
  school_ids: number[];
  term_months: number;
  min_activation_date?: Date;
  activation_date?: Date | '';
  has_curriculum: boolean;
  agreed_to_terms: boolean;
  is_outside_us: boolean;
  organization_name: string;
  license_holder_name: string;
  license_holder_id?: number;
  quote_request_v2_id: number;
  license_holder_email: string;
  billing_contact_email: string;
  billing_contact_phone: string;
  billing_contact_last_name: string;
  billing_contact_first_name: string;
  billing_contact_address_city: string;
  billing_contact_address_code: string;
  billing_contact_address_street: string;
  billing_contact_address_country: string;
  billing_contact_address_street2: string;
  billing_contact_address_province: string;
  purchase_order_file_id?: string;
  purchase_order_number?: string
  purchase_order_filename?: string
  custom_discount?: number;
  organization_profile_id?: number;
}

type SectionDetailType = {
  label: string,
  value: string | undefined | JSX.Element | Date
}

const Section: React.FC<{title: string, details?: SectionDetailType[]}> = ({title, details, children}) => {
  return (
    <Box component={Paper}
         paddingLeft={2}
         paddingTop={1}
         paddingBottom={1}>
      <SectionHeading title={title} />
      {details && details.length > 0 && details.map((detail, index) => (
        <ConfirmationKV key={index} label={detail.label} value={detail.value || ''}/>
      ))}
      {children && children}
    </Box>
  )
}

const SectionHeading: React.VFC<{title: string}> = ({title}) => {
  return (
    <Box style={{paddingBottom: 5}}>
      <Typography variant={'h2'} style={{marginBottom: 5}}>{title}</Typography>
      <Divider />
    </Box>
  )
}

export type LinkedSchool = {
  id: number,
  name: string
}

interface OrderRequestReviewPageProps {
  maxSchoolsPerRow?: number;
}
const OrderRequestReviewPage: React.FC<OrderRequestReviewPageProps> = ({ maxSchoolsPerRow = 5 }) => {
  const {params} = useRouteMatch<IRouteParams>();
  const {orderId} = params;
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [orderDetails, setOrderDetails] = useState<IOrderRequestV2 & {linkedSchools: LinkedSchool[]}>()
  const [approveDisabled, setApproveDisabled] = useState(true)
  const [POURL, setPOURL] = useState<string | undefined >( undefined);
  const sharedClasses = useSharedStyles()
  const alert = useAlert();
  const history = useHistory()
  const theme = useTheme();
  const [anonymousQuote, setAnonymousQuote] = useState<IAnonymousQuoteResponse>();

useLayoutEffect(() => {
    if (!orderDetails?.order?.num_sites) {
      return;
    }

    justFetch(endpoints.quotes, 'POST', {
      has_curriculum: orderDetails?.order?.has_curriculum,
      num_sites: orderDetails?.order?.num_sites,
      term_months: orderDetails?.order?.term_months,
      quote_request_v2_id: orderDetails?.order?.quote_request_v2_id,
      teacher_id: orderDetails?.order?.license_holder_id
    })
      .then(res => {
        if (!res.ok) {
          throw new Error();
        }
        return res.json();
      })
      .then((body: IAnonymousQuoteResponse) => {
        setAnonymousQuote(body);
      })
      .catch(e => (e) = {
      })
  }, [orderDetails]);

  const priceToString = (price: number) => {
    if (!anonymousQuote?.net_price)
    {
      return "fetching...";
    }
    return `${price.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} USD`
  }
  
  useLayoutEffect(() => {
    const getLinkedSchoolIds = (data: any) =>
    {
      if (!data?.order)
        return [];
      if (data?.order?.school_ids)
      {
        let extra = data.order.num_sites - data.order.school_ids.length;
        return data.order.school_ids.concat(Array(extra).fill(null));
      }
      return Array(data.order.num_sites).fill(null);
    }
    const fetchOrder = async () => {
      if (!orderId) {
        return
      }
      const resp = await justFetch(endpoints.godmode.orderRequest.byId(orderId), 'GET')
      const data = await resp.json()
      setOrderDetails({
        ...data, 
        linkedSchools: getLinkedSchoolIds(data)
      })
    }

    fetchOrder()
  }, [orderId]);

  useLayoutEffect(() => {
    const fetchPOUrl = async () => {
      if (!orderDetails?.order?.purchase_order_file_id) {
        return
      }
      const resp = await justFetch(endpoints.sales.purchaseOrderUrl(orderDetails?.order?.purchase_order_file_id), 'GET')
      const data = await resp.json()
      setPOURL(data['file_url']);
    }

    fetchPOUrl();
  }, [orderDetails?.order?.purchase_order_file_id]);

  const orderInfo = [
    {
      label: "Organization Name",
      value: orderDetails?.order.organization_name
    },
    {
      label: "Type",
      value: `Premium${orderDetails?.order.has_curriculum ? '+' : ''}`
    },
    {
      label: "Term",
      value: `${orderDetails?.order.term_months} months`
    },
    {
      label: "Activation Date",
      value: orderDetails?.order.activation_date || orderDetails?.order.min_activation_date || "not set"
    },
    {
      label: "Number of Schools",
      value: `${orderDetails?.order.num_sites}`
    },
    {
      label: "Price Each",
      value: priceToString(anonymousQuote?.list_price_per_site || 0)
    },
    {
      label: "Price Subtotal",
      value: priceToString(anonymousQuote?.list_price || 0)
    },
    {
      label: "Price Discount",
      value: priceToString(anonymousQuote?.discount || 0)
    },
    {
      label: "Price Total",
      value: priceToString((anonymousQuote?.net_price || 0) - ( orderDetails?.order?.custom_discount || 0))
    },

  ]

  const POInfo = [
    {
      label: "PO Number",
      value: orderDetails?.order.purchase_order_number || "N/A"
    },
    {
      label: "PO File",
      value: POURL? <Link style={{ color: purp }} href={POURL} target='_blank'>Attached File</Link> : "N/A"
    },
  ]

  const billingInfo = [
    {
      label: "Contact Name",
      value: `${orderDetails?.order.billing_contact_first_name} ${orderDetails?.order.billing_contact_last_name}`
    },
    {
      label: "Contact Email",
      value: orderDetails?.order.billing_contact_email
    },
    {
      label: "Contact Phone",
      value: orderDetails?.order.billing_contact_phone
    },
    {
      label: "Address Line 1",
      value: orderDetails?.order.billing_contact_address_street
    },
    {
      label: "Address Line 2",
      value: orderDetails?.order.billing_contact_address_street2
    },
    {
      label: "City",
      value: orderDetails?.order.billing_contact_address_city
    },
    {
      label: "State/Province",
      value: orderDetails?.order.billing_contact_address_province
    },
    {
      label: "Zip Code",
      value: orderDetails?.order.billing_contact_address_code
    },
    {
      label: "Country",
      value: orderDetails?.order.billing_contact_address_country
    }
  ]

  const accountDetails = [
    {
      label: "Name",
      value: orderDetails?.order.license_holder_name
    },
    {
      label: "Email",
      value: orderDetails?.order.license_holder_email
    },
  ]

  const updateSiteLink = (index: number, linkedSchool: LinkedSchool) => {
    if (!orderDetails) {
      return
    }
    const updatedLinkedSchools = orderDetails.linkedSchools;
    updatedLinkedSchools[index] = linkedSchool
    setOrderDetails({...orderDetails, linkedSchools: updatedLinkedSchools})
  }

  const approveOrder = async () => {
    if (!orderDetails) {
      return
    }
    setIsSubmitting(true);
    try
    {
      const processDetails: {approve: boolean, linked_schools?: LinkedSchool[], site_names?: string[]} = {
        approve: true
      }
      if (!orderDetails.order.is_outside_us) {
        processDetails.linked_schools = orderDetails.linkedSchools
      }
      if (orderDetails.order.num_sites === 1 && (!orderDetails?.order?.site_names?.[0] || orderDetails?.order?.site_names?.[0]?.length < 1)) {
        processDetails.site_names = [orderDetails.order.organization_name];
      }
      else {
        processDetails.site_names = orderDetails.order.site_names;
      }
  
      const res = await justFetch(endpoints.processOrder(orderDetails.id), 'POST', processDetails)
      if (res.ok) {
        setIsSubmitting(false);
        alert.success(`Order approved successfully.`);
        history.push(routes.godmode.sales.index)
      } else {
        const errorMessage = (await res.json())?.message || 'n/a';
        alert.error('Could not process order. Error message: ' + errorMessage);
      }
    } catch (error: any) {
      alert.error(`An error occurred: ${error?.message || error}`);
    }
    setIsSubmitting(false)
  }

  const rejectOrder = async () => {
    if (!orderDetails) {
      return
    }
    setIsSubmitting(true);
    try {
      const res = await justFetch(endpoints.processOrder(orderDetails.id), 'POST', { approve: false })
      if (res.ok) {
        setIsSubmitting(false);
        alert.success(`Order rejected successfully.`);
        history.push(routes.godmode.sales.index)
      } else {
        const errorMessage = (await res.json())?.message || 'n/a';
        alert.error('Could not process order. Error message: ' + errorMessage);
      }
    } catch (error: any) {
      alert.error(`An error occurred: ${error?.message || error}`);
    }
    setIsSubmitting(false)
  }

  const validateZipCode = (zipCode: string) => {
    const zipCodePattern = /^\d{5}(-\d{4})?$/;
    return zipCodePattern.test(zipCode);
  }
  const zipCodeToSearchSchools = () => {
    if (!orderDetails) {
      return '';
    }
    if (validateZipCode(orderDetails.order.billing_contact_address_code))
    {
      return orderDetails.order.billing_contact_address_code;
    }
    // potentially add more logic here to determine the zip code (like from the school_id and its zipcode)
    return '';
  }

  const getSiteName = (index: number) => {
    if (!orderDetails) {
      return '';
    }
    if (orderDetails.order.site_names){
      return orderDetails.order.site_names[index];
    }
    if (orderDetails?.order.organization_name){
      return orderDetails?.order.organization_name;
    }
    return '';
  }

  useEffect(()=>{
    setApproveDisabled((orderDetails?.order.is_outside_us ? false : orderDetails?.linkedSchools.some(val => !val)) || isSubmitting);
  },[orderDetails, isSubmitting]);


  const chunkArray = (array: any[], size: number) => {
    const result = [];
    for (let i = 0; i < array.length; i += size) {
      result.push(array.slice(i, i + size));
    }
    return result;
  };

  const items = Array.from({ length: orderDetails?.order.num_sites as number });
  const chunks = chunkArray(items, maxSchoolsPerRow);

  const getSchoolID = (index: number) =>
  {
//    if (orderDetails?.order.num_sites === 1 && orderDetails?.order.school_id)
//      return orderDetails?.order.school_id;
    if (orderDetails?.order?.school_ids)
      return orderDetails?.order?.school_ids[index];
    return 0;
  }
  const getOrderType = ()=>
  {
    return orderDetails? (orderDetails?.order.organization_profile_id? 'Renewal': 'New Business'): '';
  }

  return (
    <PageContainer variant={'full'}>
      <Typography variant={'h1'} style={{paddingBottom: 10}}>
        Review Order
        {(getOrderType()?.length > 0) && (<> - <span style={{ fontWeight: 'bold' }}>{getOrderType()}</span></>) }
      </Typography>
      <Box className={sharedClasses.vspacing2} display="flex" flexDirection="row" justifyContent="space-between">      
        <Box flex={1} marginRight={2}>
          <Section title={'Order Details'} details={orderInfo} />
          <Section title={'Purchased with PO'} details={POInfo} />
        </Box>
        <Box flex={1}>        
          <Section title={'Billing Information'} details={billingInfo} />
          <Section title={'Account Details'} details={accountDetails} />
        </Box>
      </Box>

        {!orderDetails?.order.is_outside_us &&
          <Section title={'School Linking'}>
            {chunks.map((chunk, rowIndex) => (
              <Box key={rowIndex}
                  display={'flex'}
                  flexDirection={'row'}
                  justifyContent={'flex-start'}
                  className={sharedClasses.hspacing2}
                  style={{
                    paddingTop: 10,
                    paddingBottom: 10
                  }}
              >
                {chunk.map((_, index) => (
                  <SiteToSchoolBox
                    key={rowIndex * maxSchoolsPerRow + index}
                    schoolID={getSchoolID(rowIndex * maxSchoolsPerRow + index)}
                    zipCode={zipCodeToSearchSchools()}
                    siteName={getSiteName(rowIndex * maxSchoolsPerRow + index)}
                    siteNum={rowIndex * maxSchoolsPerRow + index + 1}
                    onSelectedSchool={updateSiteLink}
                  />
                ))}
              </Box>
            ))}
          </Section>
        }
        {orderDetails?.order.is_outside_us &&
          <Section title={'Sites (Outside of the US)'}>
              {chunks.map((chunk, rowIndex) => (
                <Box display={'flex'}
                    key={rowIndex}
                    flexDirection={'row'}
                    justifyContent={'flex-start'}
                    className={sharedClasses.hspacing2}
                    style={{
                      paddingTop: 10,
                      paddingBottom: 10
                    }}
                >
                  {chunk.map((_, index) => (
                    <SiteNameBox
                      key={rowIndex * maxSchoolsPerRow + index}
                      siteName={orderDetails?.order.site_names ? orderDetails?.order.site_names[rowIndex * maxSchoolsPerRow + index] : ''}
                      siteNum={rowIndex * maxSchoolsPerRow + index + 1}
                    />
                  ))}
                </Box>
              ))}
          </Section>
        }
        <Box component={Paper} p={2} display={'flex'} justifyContent={'space-between'}>
          <Button color={'red'}
                  variant={'contained'}
                  onClick={rejectOrder}
          >Reject</Button>
          <Button color={'primary'}
                  variant={'contained'}
                  disabled={approveDisabled}
                  onClick={approveOrder}
          >Approve</Button>
        </Box>
    </PageContainer>
  )
}

export default OrderRequestReviewPage